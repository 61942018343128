.editorStructure{
 /* height: 500px; */
 height: 100%;
 display: flex;
 width: 100%;
}
.file{
  height: 100vh;
  /* height: 400px; */
  width: 12%;
  /* border: 1px solid black; */
  overflow-y: scroll;
}
.Tab{
  /* border: 1px solid black; */
  border-radius: 4px 4px 0px 0px;
  height: inherit;
  /* max-width: 500px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin:0px 5px;
  padding-bottom: 0%;
  box-sizing: border-box;
}
.Tab:hover{
  cursor: pointer;
}

.Tabs{
  display: flex;
  border-bottom: 3px solid rgb(255, 255, 255);
}

.cross{
  /* style={{color:"#D3D3D3",marginLeft:"4px",fontSize:"smaller",marginRight:"2px"}} */
  color:#D3D3D3;
  margin: 0px 2px 0px 4px;
  font-size: smaller;
}
.cross:hover{
  background-color: black;
}

.pythoneditor{
    display: flex;
    height: 100vh;
    /* height: inherit; */
    width: 90%;
}
#pythoneditorLeft,#pythoneditorRight{
  height: 100%;
  width: 100%;
  /* border: 1px solid black; */
}
#gap{
  height: inherit;
  width: 1%;
  background-color: whitesmoke;
  /* border: 1px solid red; */
}

#top{
  width: inherit;
  height: 5%;
  border: 1px solid rgb(255, 255, 255);
  background-color:#B6FFCE;
  /* linear-gradient(270deg, #2fb8bb 0%, #97da92 100%); */
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#bottom{
  width: inherit;
  height: 95%;
  /* border: 1px solid black; */
  box-sizing: border-box;
}



.pythonoutput{
    width: inherit;
    height: 100%;
    /* background-color: #2e1128; */
    background-color: black;
    padding: 0.5rem;
    font-size: 1rem;
    color: white;
     font-family: 'Roboto Mono', monospace;
     box-sizing: border-box;
}

 .run{
   /* height: 90%; */
   /* background: rgb(25, 135, 13); */
   /* font-size:.8rem; */
  font-weight:bold;
  font-style: normal;
   background-color: #3e46ff;
   color: rgb(255, 255, 255);
   box-shadow: none;
   margin-right: 5px;
   text-align: center;
   /* border-radius:3rem; */
   border: 1px solid #523249;
   cursor:pointer;
   /* display: flex; 
   justify-content: center;
   align-items: center; */
 }
 
 .btn{
  margin:0.5em;
  font-size:.8rem;
  padding:.5rem 2rem;
  border-radius:3rem;
  box-shadow:none;
  border: 1px solid #523249;
  text-transform: uppercase;
  cursor:pointer;
  height: 30px;
  min-width:60px;
  transition:all .35s ease-in;
  font-weight:900;
  color:rgb(0, 0, 0);
 }

 .clear{
  /* height: 90%; */
  font-size:.8rem;
  font-style: normal;
  font-weight:bold;
  background: red;
  box-shadow: none;
  color: rgb(255, 255, 255);
  margin-right: 5px;
  /* border-radius:3rem; */
  border: 1px solid #523249;
  cursor:pointer;
}

.tab{
  border: 1px solid black;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* direction: rtl; */
}

#tabsList{
  /* width: 300px; */
  border: 1px solid black;
  display: flex;
}


/* width */
.file::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.file::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
 
/* Handle */
.file::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}

.aftersaving{
  height: 30px;
  width: 200px;
  z-index: 500000;
  border: 1px solid rgb(68, 68, 68);
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 255, 0);
  color: rgba(0, 0, 0, 0.991);
  font-weight: bold;
  border-radius: 3px;
  position: fixed;
  left:30%;
  top:10%;
}